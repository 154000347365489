








import Vue from "vue";
export default Vue.component("QButton", {
  props: {
    label: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "Green"
    },
    className: {
      type: String,
      default: "",
      required: false
    },
    disable: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ButtonClass(): string {
      let color = this.color;
      if (this.disable) {
        color = "Grey";
      }
      if (this.className !== '') {
        let className = this.className;
        if (this.disable) {
          return `${className} Btn ${color}`; 
        }
        return `${className} ${color}`; 
      }  

      return `mb__button mb__block Btn ${color}`;
    }
  },
  methods: {
    handleClick(event: Event): void {
      if (this.disable) {
        return;
      }

      this.$emit("click", event);
    }
  }
});
